import React from "react";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import {Helmet} from "react-helmet";


class JoystickXboxSS extends React.Component {
    render() {
        return (
            <div className="rent">
                <Helmet>
                    <title>Xbox</title>
                </Helmet>
                <Header background="/images/IMG20241224144032.jpg" content='Xbox'/>
                <div className="container">
                    <br/>
                    <div className="alert alert-secondary">
                        Инструкция по подключению джойстика к приставке Xbox:
                    </div>
                    <div className="alert alert-secondary align-content-center text-center">
                        <video height={"500px"} style={{alignSelf:"center"}} src="/video/JoystickXboxSS.mp4"
                            /*poster="/images/way-to-cafe.png"*/  autoPlay loop controls={true}
                        />
                    </div>



                </div>
                <Footer/>
            </div>
        );
    }
}

export default JoystickXboxSS;
