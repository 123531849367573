import React from "react";

class PricingSlide extends React.Component {
    shouldComponentUpdate = () => false;

    render() {
        return (
            <section className="">
                <div className="about-price">
                    <div className="container">
                        <div className="col-sm-12">
                            <div className="about-pricing">
                                <p>Первые два часа 3.5₽ /мин, далее 3₽ в минуту
                                    <br/>Для зарегистрированных<sup>**</sup> пользователей 3₽ в минуту с первого часа
                                </p>
                                {/* <p>Далее — по 2₽ в минуту</p>*/}
                                <p>Стопчек<sup>*</sup> 790₽ (690₽ для зарегистрированных** пользователей)</p>
                                <p>Стопчек<sup>*</sup> для студентов  590₽ (490₽ для зарегистрированных** пользователей)
                                </p>
                                <br/>
                                <p><sup>*</sup>Сумма, больше которой вы не заплатите, находясь в заведении в течение одной
                                    рабочей смены (рабочая смена начинается в 9.00 и заканчивается в 8.59 следующего дня)
                                </p>
                                <p>Стопчек оплачивается только наличными, при оплате картой расчёт ведётся по основному
                                    тарифу</p>
                                <br/>
                                <p>
                                    ** У нас действует своя программа лояльности, зарегистрироваться в которой можно при посещении антикафе или заранее
                                    <a href="https://t.me/zdver_bot"> пройти регистрацию</a>.
                                    <br/>
                                    <br/>
                                    После регистрации вы получите свои индивидуальный QR-код и,
                                    если вы подписаны на наш канал в телеграме и состоите в чате,
                                    то ваш статус будет изменен на <b>Хороший Знакомый </b>и стоимость посещения для вас будет снижена.
                                </p>
                                <br/>

                            </div>
                        </div>



                        <br/>
                    </div>
                    <br/>
                    <br/>

                </div>
                <br/>
                <br/>
            </section>

        );
    }
}

export default PricingSlide;
